import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useApi } from "../../contexts/ApiProvider";
import { useFlash } from "../../contexts/FlashProvider";
import { formatToDate } from "../../helpers/Utility";
import Avatar from "../../components/Avatar";

export default function ItemListPage() {
  const query = useLocation().search;
  const navigate = useNavigate();
  const api = useApi();
  const flash = useFlash();

  const [items, setItems] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((itemId) => itemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(items.map((item) => item._id));
    } else {
      setSelected([]);
    }
  };

  const deleteMultipleItem = async () => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.post(`/api/items/more`, selected);
      if (!response.error) {
        setItems(items.filter((item) => !selected.includes(item._id)));
        setFiltered(items.filter((item) => !selected.includes(item._id)));
        setSelected([]);
      }
    }
  };
  const deleteItem = async (id) => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.delete(`/api/items/${id}`);
      if (!response.error) {
        setItems(items.filter((item) => item._id !== id));
        setFiltered(items.filter((item) => item._id !== id));
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/items/search${query}`);
      if (!response?.error) {
        setItems(response?.items);
        setFiltered(response?.items);
        setPages(response?.pages);
        setPage(response?.page);
      } else {
        flash(`${response.error}`, "danger");
      }
    })();
  }, [api, query]);

  return (
    <div>
      <header>
        <h2>
          <span>Formations</span>
        </h2>

        <div className="options-wrapper">
          <Link to={`add`} className="btn btn-main">
            <span className="las la-plus"></span>
            <span>CREER</span>
          </Link>
        </div>
      </header>
      <main>
        {items === undefined ? (
          <Spinner />
        ) : (
          <>
            <div className="action-bar">
              <div className="search-wrapper">
                <span className="las la-search"></span>
                <input
                  type="search"
                  placeholder="Rechercher"
                  onChange={(e) => navigate(`?q=${e.target.value}`)}
                />
              </div>
              <div className="my-4">
                {selected.length > 0 && (
                  <div>
                    <button
                      className="btn btn-main-outline"
                      disabled={selected.length === 0}
                      onClick={deleteMultipleItem}
                    >
                      SUPPRIMER LA SELECTION
                    </button>
                  </div>
                )}
              </div>
            </div>
            {items.length === 0 ? (
              <div>
                <div className="no-data">
                  <p>
                    Aucunes formations trouvées. Cliquez sur le lien ci-dessous
                    pour en ajouter
                  </p>
                  <Link to={`/items/add`} className="btn btn-main-outline">
                    CREER
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <table className="table">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input type="checkbox" onChange={handleSelectAll} />
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Image
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Titre / Description
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Prix
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Date
                        </th>
                        <th scope="col" className="py-3 px-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtered?.map((item) => (
                        <tr
                          key={item._id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="px-6">
                            <input
                              type="checkbox"
                              checked={selected.includes(item._id)}
                              onChange={() => handleSelect(item._id)}
                            />
                          </td>
                          <td
                            scope="row"
                            className="px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item._id && (
                              <>
                                <Avatar
                                  img={
                                    item.images.length > 0
                                      ? `${process.env.REACT_APP_BACKEND_URL}/image/${item.images[0].name}`
                                      : null
                                  }
                                  text={item.name}
                                />
                              </>
                            )}
                          </td>
                          <td
                            scope="row"
                            className="px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <Link to={`/items/${item._id}`} className="item">
                              <h3>{item.title}</h3>
                              <p className="">
                                {item.description.length > 100
                                  ? `${item.description.substr(0, 100)}...`
                                  : item.description}
                              </p>
                              <div className="d-flex">
                                <span>
                                  <i class="bi bi-calendar-date-fill"></i> Date
                                  :{item.date}
                                </span>
                                <span>
                                  <i class="bi bi-alarm-fill"></i> Durée :{" "}
                                  {item.duration}
                                </span>
                                <span>
                                  <i class="bi bi-geo-alt-fill"></i>Lieu :{" "}
                                  {item.place}
                                </span>
                              </div>
                            </Link>
                          </td>

                          <td
                            scope="row"
                            className="px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <h5>{item.discountPrice} F.CFA</h5>
                            <del>{item.price} F.CFA</del>
                          </td>
                          <td
                            scope="row"
                            className="px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {formatToDate(item.createdAt)}
                          </td>
                          <td className="px-6 text-right">
                            <div className="table-actions">
                              <Link
                                className="btn btn-outline"
                                to={`/items/${item._id}/edit`}
                              >
                                <span className="icon las la-edit"></span>
                              </Link>
                              <button
                                className="btn btn-main"
                                onClick={() => deleteItem(item._id)}
                              >
                                Supprimer
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {/* Pagination */}
                <nav aria-label="Page navigation example">
                  {pages && (
                    <ul className="pagination justify-content-center">
                      {[...Array(pages)].map((p, i) => (
                        <li className="page-item">
                          <Link
                            className={`page-link ${p === page ? "" : null}`}
                            key={i}
                            to={`?page=${i + 1}`}
                          >
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </nav>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}
