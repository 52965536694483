import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useUser } from "../../contexts/UserProvider";
import ImageSlider from "../../components/ImageSlider";
import Spinner from "../../components/Spinner";
import { IoStar, IoStarHalf } from "react-icons/io5";

export default function CustomerDetailsPage() {
  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);

  const itemId = useParams().itemId;
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (itemId) {
      (async () => {
        const response = await api.get(`/api/items/${itemId}`);
        setProduct(response);
        setImages(response.images);
      })();
    }
  }, [itemId]);

  return (
    <>
      {product === undefined ? (
        <Spinner />
      ) : (
        <div className="product-details-container">
          <div className="product-image-slider">
            <ImageSlider slides={product.images} />
          </div>
          <div className="product-info">
            <h1>{product.name}</h1>
            <p className="product-price">
              {product.discountPrice}F.CFA <del>{product.price}F.CFA</del>
            </p>
            <div className="product-rating">
              <IoStar className="product-rating-star" />
              <IoStar className="product-rating-star" />
              <IoStar className="product-rating-star" />
              <IoStar className="product-rating-star" />
              <IoStarHalf className="product-rating-star" />
            </div>
            <div className="product-variants"></div>
            <p className="product-description">{product.description}</p>
            <div className="product-qa"></div>
            <div className="product-related"></div>
            <div className="product-social"></div>
            <div className="product-trust"></div>
            <div className="product-quantity">
              {product.stock > 0 ? (
                <span className="badge bg-warning">Disponible</span>
              ) : (
                <span className="badge bg-secondary">Indisponible</span>
              )}
              <span className="">Quantité Disponible {product.stock}</span>
            </div>
            <div className="product-checkout">
              <button className="btn btn-main">
                <span className="las la-shopping-bag"></span>
                <span>Acheter</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
