import React from "react";

export default function NotificationPage() {
  return (
    <div>
      <header>
        <div>
          <h2>
            <span>Notifications</span>
          </h2>
          <p>Gérer vos notifications</p>
        </div>
      </header>
      <main></main>
    </div>
  );
}
