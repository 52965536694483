import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlinePlusCircle } from "react-icons/ai";

const ProductSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
});

export default function CustomerEditPage() {
  const [customer, setCustomer] = useState();
  const [image, setImage] = useState();

  const customerId = useParams().customerId;
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.currentTarget.files[0]);
    formik.setFieldValue("profilePicture", e.currentTarget.files[0]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: customer?.firstName || "",
      lastName: customer?.lastName || "",
      about_me: customer?.about_me || "",
      telephone: customer?.telephone || "",
      tags: customer?.tags || "",
      category: customer?.category || "",
      discountPrice: customer?.discountPrice || "",
      theme: customer?.theme || "",
      isFeatured: customer?.isFeatured || false,
      isArchived: customer?.isArchived || false,
      profilePicture: null,
    },
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      // const newForm = new FormData();
      // if (values.image) {
      //   newForm.append("imageMsg", values.image);
      // }
      // newForm.append("firstName", values.firstName);
      // newForm.append("lastName", values.lastName);
      // newForm.append("telephone", values.telephone);
      // newForm.append("email", values.email);
      // newForm.append("birthday", values.birthday);
      // newForm.append("gender", values.gender);
      // newForm.append("description", values.description);

      if (customer) {
        const response = await api.put(`/api/users/${customerId}`, values);
        if (!response.error) {
          flash(`Informations du client mise à jour`, "success");
          navigate(`/customers`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      } else {
        const response = await api.post(`/api/customers`, values);
        if (!response.error) {
          flash(`Nouveau client crée`, "success");
          navigate(`/customers`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      }
    },
  });

  useEffect(() => {
    if (customerId) {
      (async () => {
        const response = await api.get(`/api/customers/${customerId}`);
        setCustomer(response);
        setImage(response.profilePicture);
      })();
    }
  }, [customerId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            <h2>Nouveau client</h2>
          </div>
        </div>
        <div className="app-form-telephone">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Nom <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="Entrer le nom..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Prénoms <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Entrer le(s) prénom(s)..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Téléphone <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="telephone"
                className="form-control"
                value={formik.values.telephone}
                onChange={formik.handleChange}
                placeholder="Numéro de téléphone..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Email adresse <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Adresse email..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Genre <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
              >
                <option value="">Choisir un genre</option>
                <option value={`H`}>Homme</option>
                <option value={`F`}>Femme</option>
              </select>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.about_me}
                onChange={formik.handleChange}
                placeholder="Entrer la about_me..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Charger votre logo <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3"
                    color="#555"
                  />
                </label>
                {image && (
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                    }
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                    style={{ width: "120px", height: "120px" }}
                  />
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-customers-center justify-telephone-between">
              <Link to={`/customers`} className="btn btn-main-outline">
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
