import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useUser } from "../../contexts/UserProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useShops } from "../../contexts/ShopProvider";

const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export default function CategoryEditPage() {
  const [category, setCategory] = useState();
  const [image, setImage] = useState();

  const categoryId = useParams().categoryId;

  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { user } = useUser();

  const handleImageChange = (e) => {
    setImage(e.currentTarget.files[0]);
    formik.setFieldValue("image", e.currentTarget.files[0]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "",
      description: category?.description || "",
      image: null,
    },
    validationSchema: CategorySchema,
    onSubmit: async (values) => {
      const newForm = new FormData();
      if (values.image) {
        newForm.append("imageMsg", values.image);
      }
      newForm.append("name", values.name);
      newForm.append("description", values.description);
      newForm.append("owner", user._id);

      if (category) {
        const response = await api.put(
          `/api/categories/${categoryId}`,
          newForm
        );
        if (!response.error) {
          flash(`Catégorie mise à jour`, "success");
          navigate(`/categories`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      } else {
        const response = await api.post(`/api/categories`, newForm);
        if (!response.error) {
          flash(`Catégorie créer`, "success");
          navigate(`/categories`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      }
    },
  });

  useEffect(() => {
    if (categoryId) {
      (async () => {
        const response = await api.get(`/api/categories/${categoryId}`);
        setCategory(response);
        setImage(response.imageUrl);
      })();
    }
  }, [categoryId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            <h2>Nouvelle Catégorie</h2>
          </div>
        </div>
        <div className="app-form-content">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Nom de la catégorie <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Entrer le nom de votre boutique..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Decrivez la categorie..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Charger une image <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3"
                    color="#555"
                  />
                </label>
                {image && (
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                    }
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                    style={{ width: "120px", height: "120px" }}
                  />
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-items-center justify-content-between">
              <Link to={`/categories`} className="btn btn-main-outline">
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
